import { makeObservable } from "mobx"

class AdService {
  constructor() {
    makeObservable(this)
    this.init()
  }

  public async init() {
    // init
  }

  public addBingUetqToHead(event: string = "install", plyload: {[key: string]: any} = {}) {
    const script = document.createElement("script")
    script.type = "text/javascript"
    script.innerHTML = `
      window.uetq = window.uetq || []; window.uetq.push('event', '${event}', ${JSON.stringify(plyload)});
    `
    document.head.appendChild(script)
  }
}

export const adService = new AdService()
